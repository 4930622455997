<template lang="html">
  <div style="font-size: 16px" class="tw-text-gray-900 tw-font-sans">
    <v-layout row wrap>
      <v-flex xs4 offset-xs4>
        <v-alert type="info" :value="mustConfirmEmail">
          A link has been sent to your email address, please confirm your address then try logging in again.
          <a @click="resendEmail">Resend Verification Email</a>
        </v-alert>
      </v-flex>
      <v-flex xs8 offset-xs2 class="d-flex justify-center text-sm-center">
        <SsoCard v-if="displaySso" :onclose="closeSso" />
        <div v-bind:style="getClass()">
          <div id="firebaseui-auth-container" :class="{ 'tw-opacity-0': hideFbUi }"></div>
          <template v-if="!signupMode">
            <v-btn @click="doXeroLogin" color="white" class="sso-btn" style="color: #757575">
              <img
                src="icons/xero.svg"
                alt="xero logo"
                class="v-icon"
                style="width: 22px; height: 22px; margin: -2px 14px 0 -2px"
              />
              Sign in with Xero
            </v-btn>
            <v-btn @click="openSso" color="success" class="sso-btn">
              <v-icon size="22" class="mr-3">key</v-icon>
              Sign in with SSO
            </v-btn>
            <div v-if="!showBwAuthLink" @dblclick="showBwAuthLink = true" class="tw-h-20"></div>
            <v-btn
              color="indigo"
              class="sso-btn tw-text-white tw-overflow-hidden tw-transition-all"
              :class="{ 'tw-invisible': !showBwAuthLink }"
              :style="{
                height: showBwAuthLink ? '40px' : '0px',
              }"
              @click="doBwAuth"
            >
              <img class="tw-h-5 tw-mr-4" src="/mark.png" style="margin-left: -2px" />
              Bitwave Auth
              <fa class="tw-ml-8" icon="fa-solid fa-flask" />
              <fa class="tw-ml-2 tw-relative" style="top: -1px" icon="fa-solid fa-external-link-alt" />
            </v-btn>
          </template>
        </div>
      </v-flex>
    </v-layout>
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="4000" :top="true">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import 'firebase/auth';

import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import { AuthService } from '@/services/authService';

import { baConfig } from '../../config';
import { getSiblingApplicationUrl } from '../BaseVue';
import SsoCard from '../components/auth/SsoCard';
import { MUT_IS_LOADING } from '../store';
import { urlJoin } from '../utils/urlJoin';

const authService = new AuthService();

export default {
  name: 'auth',
  props: ['updateUsersTable', 'firebase'],
  computed: {
    bwAuthUrl() {
      return process.env.VUE_APP_AUTH_SVC_URL;
    },
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      mustConfirmEmail: false,
      email: '',
      displaySso: false,
      showBwAuthLink: false,
      hideFbUi: false,
      signupMode: false,
      redirectToSibling: false,
      siblingUrl: '',
    };
  },
  async mounted() {
    const urlParams = this.getSearchParams();
    this.checkSiblingReferral();
    if (urlParams.get('signup')) {
      this.signupMode = true;
    }
    if (
      urlParams.get('legacy') ||
      this.signupMode ||
      process.env.VUE_APP_USE_BW_AUTH_SVC !== 'true' ||
      !this.shouldUseNewAuthSvc()
    ) {
      await this.mountAuthUi();
    } else {
      let redirect =
        typeof this.$router.currentRoute.query.redirect === 'string'
          ? this.$router.currentRoute.query.redirect
          : '/home';
      if (!redirect.startsWith('/')) {
        redirect = '/' + redirect;
      }
      // wait for loading to finish
      if (this.$store.state.isLoading) {
        await new Promise((resolve) => {
          const unsub = this.$store.subscribe((mutation, state) => {
            if (mutation.type === MUT_IS_LOADING && state.isLoading === false) {
              unsub();
              resolve();
            }
          });
        });
      }
      const user = this.$store.state.user;
      if (user) {
        if (this.redirectToSibling) {
          this.gotoSibling();
        }
        await this.$router.push(redirect);
        return;
      }

      this.doBwAuth();
    }
  },
  methods: {
    shouldUseNewAuthSvc() {
      // Retrieve the existing cookie value
      const cookieValue = Cookies.getJSON('authRollout');
      const currentPercentage = parseInt(process.env.VUE_APP_NEW_AUTH_SVC_PERCENTAGE || '0');
      // If the cookie does not exist or the stored percentage is less than the current percentage
      if (!cookieValue || (!cookieValue.useNewAuth && cookieValue.percentage < currentPercentage)) {
        // Generate a random number between 0 and 100
        const randomChance = Math.random() * 100;

        // Determine if the user should see the new auth service
        const useNewAuth = randomChance < currentPercentage;

        // Update the cookie with the new decision and the current percentage
        Cookies.set('authRollout', { useNewAuth, percentage: currentPercentage }, { expires: 365 });
        return useNewAuth;
      }
      return cookieValue.useNewAuth;
    },

    popSnackbar(message, color) {
      this.snackbarColor = color;
      this.snackbarText = message;
      this.snackbar = false;
      this.snackbar = true;
    },
    async resendEmail() {
      await firebase.auth().currentUser.sendEmailVerification();
      this.popSnackbar(this.$t('_emailValidationSent'), 'info');
    },
    async gotoSibling() {
      localStorage.removeItem('siblingApplicationId');
      window.location.href = this.siblingUrl;
    },
    checkSiblingReferral() {
      const urlParams = this.getSearchParams();
      const siblingId = urlParams.get('siblingLogin');
      if (siblingId) {
        localStorage.setItem('siblingApplicationId', siblingId);
        this.siblingUrl = getSiblingApplicationUrl(siblingId);
        this.redirectToSibling = true;
      }
    },
    async mountAuthUi() {
      this.checkSiblingReferral();
      // check if user is logged in and navigaste away
      let redirect =
        typeof this.$router.currentRoute.query.redirect === 'string'
          ? this.$router.currentRoute.query.redirect
          : '/home';
      if (!redirect.startsWith('/')) {
        redirect = '/' + redirect;
      }
      const fbUser = firebase.auth().currentUser;
      if (fbUser) {
        if (fbUser.emailVerified === true) {
          if (this.redirectToSibling) {
            return this.gotoSibling();
          }
          await this.$router.push(redirect);
        } else {
          this.mustConfirmEmail = !fbUser.emailVerified;
        }
      }

      const user = this.$store.state.user;
      if (user) {
        if (this.redirectToSibling) {
          return this.gotoSibling();
        }
        await this.$router.push(redirect);
        return;
      }

      function signInSuccessWithAuthResult(authResult) {
        if (authResult.additionalUserInfo.isNewUser && authResult.additionalUserInfo.providerId === 'password') {
          this.mustConfirmEmail = true;
          // await ui.delete();
          // this.mountAuthUi();
          this.popSnackbar(this.$t('_emailValidationSent'), 'warning');
          authResult.user.sendEmailVerification();
          return false;
          // this.$router.push({ name: "confirmEmail"});
        } else if (!authResult.user.emailVerified) {
          this.mustConfirmEmail = true;
          ui.delete();
          this.mountAuthUi();
          this.popSnackbar(this.$t('_emailNotValidated'), 'warning');
          return false;
          // this.$router.push({ name: "confirmEmail"});
        }
        if (this.redirectToSibling) {
          return this.gotoSibling();
        }
        return true;
      }

      const uiConfig = {
        callbacks: {
          uiShown() {
            // this.$store.commit(MUT_IS_LOADING, false);
          },
          signInSuccessWithAuthResult: signInSuccessWithAuthResult.bind(this),
        },
        signInSuccessUrl: this.redirectToSibling ? this.siblingUrl : baConfig.redirect_url + '/#' + redirect,
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        // Terms of service url.
        // tosUrl: "http://localhost:8080"
        signInFlow: 'popup',
      };

      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
      if (this.signupMode) {
        this.hideFbUi = true;
        setTimeout(() => (this.hideFbUi = false), 2000); // fail safe so we never get a blank screen
        let step = 1;
        const fbAuthContainer = document.getElementById('firebaseui-auth-container');
        const mo = new MutationObserver(() => {
          if (step === 1) {
            const signInWithEmailButton = Array.from(fbAuthContainer.querySelectorAll('.firebaseui-idp-button')).find(
              (x) => x.innerText?.endsWith('Sign in with email')
            );
            if (signInWithEmailButton) {
              signInWithEmailButton.click();
              step = 2;
            }
          } else if (step === 2) {
            const emailInput = fbAuthContainer.querySelector('.firebaseui-id-email');
            const submitBtn = fbAuthContainer.querySelector('.firebaseui-id-submit');
            if (emailInput && submitBtn) {
              emailInput.value = uuid() + '@' + uuid() + '.com';
              submitBtn.click();
              step = 3;
            }
          } else if (step === 3) {
            const emailInput = fbAuthContainer.querySelector('.firebaseui-id-email');
            if (emailInput) {
              emailInput.value = '';
              emailInput.focus();
              step = 4;
              mo.disconnect();
              this.hideFbUi = false;
            }
          }
        });
        mo.observe(document.getElementById('firebaseui-auth-container'), {
          childList: true,
        });
      }
    },
    openSso() {
      this.displaySso = true;
    },
    closeSso() {
      this.displaySso = false;
    },
    getClass() {
      return {
        display: this.displaySso ? 'none' : 'block',
      };
    },
    doXeroLogin() {
      window.location.href = urlJoin(process.env.VUE_APP_API_URL, '/xero-login');
    },
    doBwAuth() {
      this.$store.commit(MUT_IS_LOADING, true);
      authService.sendToAuth();
    },
    getSearchParams() {
      return new URLSearchParams(window.location.href.match(/(?<=\?)([^?]+)$/)?.[0]);
    },
  },
  components: {
    SsoCard: SsoCard,
  },
};
</script>

<style>
@import '~firebaseui/dist/firebaseui.css';
.mdl-button--colored {
  background-color: #37474f !important;
}
.mdl-button--primary.mdl-button--primary {
  color: #37474f !important;
}
.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #546e7a !important;
}
.firebaseui-link {
  color: #37474f !important;
}

.sso-btn {
  width: 220px;
  text-align: left;
  margin: 0 auto 15px auto;
  display: block;
  height: 40px;
}
.sso-btn .v-btn__content {
  justify-content: left;
  text-transform: none;
}

.sso-btn .v-icon {
  width: 18px;
  height: 18px;
  margin-right: 16px;
}
</style>
