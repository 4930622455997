<template>
  <v-card max-width="450px">
    <v-container>
      <v-form ref="invoicingForm" v-model="valid" lazy-validation>
        <v-text-field @keydown.enter="requestSso" v-model="email" label="Email"></v-text-field>
        <div class="d-flex justify-space-between">
          <v-btn @click="onCancel" color="dark" class="action-btn"> Cancel </v-btn>
          <v-btn @click="requestSso" color="success" class="action-btn" :loading="isLoading"> Login </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import * as axios from 'axios';

import { MUT_SNACKBAR } from '../../store';
export default {
  props: ['onclose'],
  data() {
    return {
      error: null,
      isLoading: false,
      valid: true,
      email: '',
    };
  },
  computed: {},
  methods: {
    async requestSso() {
      event.preventDefault();
      this.isLoading = true;
      try {
        const resp = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_API_URL}users/sso`,
          withCredentials: false,
          params: {
            email: this.email,
          },
        });
        if (resp && resp.data && resp.data.idpSignOnUrl) {
          window.location.href = resp.data.idpSignOnUrl;
        } else {
          this.showErrorMessage();
        }
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.showErrorMessage();
        this.isLoading = false;
      }
    },
    onCancel() {
      this.email = '';
      this.isLoading = false;
      this.onclose();
    },
    showErrorMessage() {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: "You can't access with SSO",
      });
    },
  },
};
</script>

<style>
.action-btn {
  max-width: 100px;
}
</style>
